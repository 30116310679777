@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('fonts/OpenSans-Regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Italic-webfont.woff') format('woff'),
    url('fonts/OpenSans-Italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-ExtraBold-webfont.woff') format('woff'),
    url('fonts/OpenSans-ExtraBold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
    url('fonts/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Bold-webfont.woff') format('woff'),
    url('fonts/OpenSans-Bold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
    url('fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Light-webfont.woff') format('woff'),
    url('fonts/OpenSans-Light-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
    url('fonts/OpenSans-LightItalic-webfont.ttf') format('opentype');
    font-weight: 100;
    font-style: italic;
}
