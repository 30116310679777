@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Regular-webfont.woff") format("woff"), url("fonts/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Italic-webfont.woff") format("woff"), url("fonts/OpenSans-Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-ExtraBold-webfont.woff") format("woff"), url("fonts/OpenSans-ExtraBold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-ExtraBoldItalic-webfont.woff") format("woff"), url("fonts/OpenSans-ExtraBoldItalic-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Bold-webfont.woff") format("woff"), url("fonts/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-BoldItalic-webfont.woff") format("woff"), url("fonts/OpenSans-BoldItalic-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Light-webfont.woff") format("woff"), url("fonts/OpenSans-Light-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-LightItalic-webfont.woff") format("woff"), url("fonts/OpenSans-LightItalic-webfont.ttf") format("opentype");
  font-weight: 100;
  font-style: italic;
}
html {
  font-family: "Open Sans", "Arial", "sans-serif";
}

/**
  Local mixins for @apply
*/
.link-hover {
  -webkit-text-decoration-line: underline;
  -moz-text-decoration-line: underline;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

/** ------------------- **/
.initiative__hero-text h1 {
  color: white;
}

#participatory-process-homepage-highlighted-proposals .button {
  display: none;
}

/**
  Menu bar
*/
#menu-bar {
  @apply border-t border-solid text-gray-3;
}

header .menu-bar__container {
  @apply bg-white;
}

.menu-bar__breadcrumb-desktop__dropdown-content-secondary,
header .menu-bar__main-dropdown {
  @apply ring-1;
  --tw-ring-color: #ccc;
}

header .menu-bar__breadcrumb-mobile {
  @apply text-xs !important;
}

header .menu-bar__breadcrumb-desktop > * {
  @apply text-black;
}

/**
  Footer
*/
footer .main-footer {
  @apply bg-black;
}
footer .main-footer .font-semibold {
  @apply font-normal;
}
footer .main-footer .font-semibold:hover {
  @apply link-hover;
}
footer .mini-footer {
  background-color: #262626;
}
footer h2 {
  @apply text-white;
  font-weight: bold !important;
}

/**
  Hero, start
*/
.hero-alt__text .button__secondary,
#hero-cta.button__secondary {
  @apply bg-white text-primary;
}
.hero-alt__text .button__secondary:hover,
#hero-cta.button__secondary:hover {
  @apply bg-white link-hover text-primary !important;
}

/**
  Hero, participation space
*/
.participatory-space__hero-slogan {
  @apply text-white mb-4;
}

.participatory-space__hero {
  @apply backdrop-filter-none bg-background;
}

.participatory-space__hero-text {
  @apply text-black;
}

/**
  Backgrounds, sections
*/
#how_to_participate {
  @apply bg-background;
}

/**
  Cards
*/
.card__highlight {
  @apply bg-white shadow-none ring-transparent;
}
.card__highlight:hover {
  @apply ring-transparent;
}
.card__highlight:nth-child(1) {
  background-color: #e3e8e9;
}
.card__highlight:nth-child(2) {
  background-color: #dbeff8;
}
.card__highlight:nth-child(3) {
  background-color: #dbede2;
}
.card__highlight:nth-child(4) {
  background-color: #fbe1ec;
}
.card__highlight:nth-child(5) {
  background-color: #ece2f1;
}
.card__highlight:nth-child(6) {
  background-color: #fde3d6;
}
.card__highlight:nth-child(7) {
  background-color: #d6ebed;
}
.card__highlight:nth-child(8) {
  background-color: #fff2b0;
}

/*
--color-dark-blue-light#e3e8e9
--color-blue-light#dbeff8
--color-green-light#dbede2
--color-pink-light#fbe1ec
--color-purple-light#ece2f1
--color-red-light#fde3d6
--color-turquoise-light#d6ebed
--color-yellow-light#fff2b0

--color-dark-blue-lightest#f5f7f8
--color-blue-lightest#f2fafc
--color-green-lightest#f0f9f3
--color-pink-lightest#fdf4f8
--color-purple-lightest#f6f1f9
--color-red-lightest#fef5f1
--color-turquoise-lightest#f2f9fa
--color-yellow-lightest#fffcef
*/
.card__grid {
  @apply bg-white shadow-none ring-1;
  --tw-ring-color: #e5e5e5;
}
.card__grid:hover {
  @apply bg-background;
  --tw-ring-color: #e5e5e5;
}

.card__highlight-metadata > *,
.card__grid-metadata > * {
  @apply max-w-full;
}

#floating-helper-tip {
  display: none;
}

/**
  Budgets
*/
.budget-summary__progressbar-legend-strong {
  white-space: nowrap;
}

/**
  Headlines
*/
h1, h2, h3, h4, h5 {
  @apply font-bold text-black;
}

/**
  Links & Buttons
*/
.button__transparent {
  @apply text-black border-black;
}

a .text-secondary {
  @apply text-black underline font-bold;
}
a .text-secondary:hover {
  @apply link-hover;
}

.activity__content > span:first-child a {
  text-decoration: underline;
}
.activity__content > span:first-child a:hover {
  @apply link-hover;
}

header .menu-bar__main-dropdown__menu a:hover {
  @apply link-hover;
}

/**
  Reset colors & decorations
*/
header .menu-bar__breadcrumb-mobile__dropdown-trigger span,
header .menu-bar__breadcrumb-mobile__dropdown-trigger,
header .menu-bar__main-dropdown__menu a,
header .menu-bar__breadcrumb-desktop [data-target*=dropdown] > svg,
.border-secondary,
.filter-container input[type=checkbox]:checked, .filter-container input[type=checkbox]:indeterminate, .filter-container input[type=radio]:checked, .filter-container input[type=radio]:indeterminate,
.card__list-title,
.activity__content > span:first-child a,
.home__section-participate-grid__icon,
.button__text-secondary,
header .main-bar__links-mobile,
[data-target*=dropdown] > svg,
header .main-bar__links-mobile__trigger,
header .main-bar__links-desktop__item {
  @apply text-black;
}

.home__section-title::after,
.decorator::after,
.title-decorator::after {
  display: none;
}